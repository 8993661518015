import React from "react";
import "../../css/bootstrap.css";
import "../../css/common.css";
import "../../css/modal.css";
import campCss from "../../css/campaign.module.css";
import { Section } from "../../components/projects/OngoingTemplate";
import { graphql } from "gatsby";
import banner from "../../images/campaign/plutus-banner.jpg";
import bannerMobile from "../../images/campaign/plutus-banner-mobile.jpg";
import CampaignModal, {
  CampaignContextProvider,
} from "../../components/campaign/CampaignModal";
import CampaignHeader from "../../components/campaign/CampaignHeader";

const Plutus = ({ data }) => {
  const project = data.allCompletedJson.edges[0].node;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script>(function(w, d, s, l, i) {
                        w[l] = w[l] || []
                        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
                        var f = d.getElementsByTagName(s)[0],
                            j = d.createElement(s),
                            dl = l != "dataLayer" ? "&l=" + l : ""
                        j.async = true
                        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
                        f.parentNode.insertBefore(j, f)
                    })(window, document, "script", "dataLayer", "GTM-ML9FXC9");</script>`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-ML9FXC9" height="0" width="0"style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <CampaignContextProvider>
        <CampaignHeader />
        <div className="page-container">
          <div className="relative">
            <picture className="w-100">
              <source media="(min-width: 650px)" srcSet={banner} />
              <source media="(min-width: 250px)" srcSet={bannerMobile} />
              <img src={banner} className="w-100" alt="" />
            </picture>
          </div>
          <div className={`${campCss.bottomBar} pt-4 pb-4 mb-5`}>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-4 text-center mb-3">
                  <div className="p-3 border">
                    <h4>FABULOUS PRICE</h4>
                    <h1 className="font-weight-bold mb-0">12,499</h1>
                    <span className="f-14">per sq.ft</span>
                  </div>
                </div>
                <div className="col-lg-8 text-center">
                  <h4>Make a Stunning Grand Entry</h4>
                  <p>
                    The tastefully designed facade sets the tone for the
                    building, brimming with modernity and refinement.
                  </p>
                  <div className="row align-items-center">
                    <div className="col-sm-4 text-center mb-3 border-right">
                      <h1 className="font-weight-bold mb-1">152</h1>
                      <h6>Total units</h6>
                    </div>
                    <div className="col-sm-4 text-center mb-3  border-right">
                      <h1 className="font-weight-bold mb-1">3 BHK</h1>
                      <h6>1755 - 2139 sq.ft</h6>
                    </div>
                    <div className="col-sm-4 d-flex justify-content-center align-items-center mb-3">
                      <div className="text-center">
                        <h1 className="font-weight-bold mb-1">4 BHK</h1>
                        <h6>3454 - 3617 sq.ft</h6>
                      </div>
                      <div className="f-14 mb-3">Sky villas</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CampaignModal />
          {project && <Section project={project} logo={project.logo} />}
        </div>
      </CampaignContextProvider>
    </>
  );
};

export const query = graphql`
  {
    allCompletedJson(filter: { slug: { eq: "the-plutus-residence" } }) {
      edges {
        node {
          footer_desc
          short_desc
          slug
          rera
          title
          tagline

          logo {
            childImageSharp {
              fluid(maxWidth: 280) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          brouchureUrl
          synopsis
          specification {
            a
            q
          }
          highlights
          gMapUrl
          gallery {
            title
            images {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          banner {
            childImageSharp {
              fluid {
                src
              }
            }
          }
          bg {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;

export default Plutus;
